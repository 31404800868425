.about-us {
  display: flex;
}

.about-us-left {
  flex-grow: 1;
  justify-content: flex-end;
}

.about-us-middle {
  flex-grow: 1;
  justify-content: center;
}

.about-us-right {
  justify-content: flex-end;

  + .about-us-right {
    flex-grow: 1;
  }
}